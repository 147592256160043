import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import api from "service/api";
import { NewEditor } from "components/Editor";
import { Button, CardBody, FormGroup, Modal, Row, Col } from "reactstrap";

const ModalMeuSputiContent = ({ ItemContent, update, setUpdate }) => {
  const [defaultModal, setDefaultModal] = useState(false);
  const [notificationModal, setNotificationModal] = useState(false);
  const [formModal, setFormModal] = useState(false);
  const { register, handleSubmit, reset, setValue } = useForm();
  const [, setLoading] = useState(false);
  const [textContent, setTextContent] = useState("");
  const [initialContent, setInitialContent] = useState("");
  const toggleModal = (state) => {
    switch (state) {
      case "defaultModal":
        setDefaultModal(!defaultModal);
        break;
      case "notificationModal":
        setNotificationModal(!notificationModal);
        break;
      case "formModal":
        setFormModal(!formModal);
        break;
      default:
        break;
    }
  };

  const emptyContent = {
    topicName: "",
    subtopic: "",
    content: "",
    reference: "",
    assistant: {
      name: "",
    },
  };
  const [content, setContent] = useState(emptyContent);

  useEffect(() => {
    if (ItemContent) {
      setContent({
        topicName: ItemContent.topic_name,
        subtopic: ItemContent.subtopic,
        content: ItemContent.text,
        reference: ItemContent.reference,
        assistant: {
          name: ItemContent.assistant,
        },
      });
      setTextContent(ItemContent.content);
      setInitialContent(ItemContent.content);
      setValue("topic", ItemContent.topic);
      setValue("reference", ItemContent.reference);
      setValue("username", ItemContent.user);
    }
  }, [ItemContent]);

  const onSubmit = async (data) => {
    setLoading(true);
    let contentSubmit = {};
    data.textHTML = textContent.trim();
    data.text = textContent
      .replace(/<[^>]*>/gi, " ")
      .replace(/<\/[^>]*>/gi, " ")
      .replace(/\s+/gi, " ")
      .trim();

    if (data.textHTML.length === 0) {
      toast.error("Conteúdo não pode ser vazio!");
      setLoading(false);
      return;
    }

    if (data.username.trim().length === 0) {
      toast.error("Usuário não pode ser vazio!");
      setLoading(false);
      return;
    }

    const hasUser = await api
      .get(`/users?username=${data.username}`)
      .then((response) => {
        if (response?.data?.users?.length === 0) {
          toast.error("Usuário não encontrado!");
          setLoading(false);
          return false;
        }
        return true;
      })
      .catch((error) => {
        toast.error("Erro ao buscar usuário!");
        setLoading(false);
        return false;
      });

    if (hasUser) {
      contentSubmit.topic_name = "meu-sputi";
      contentSubmit.text = data.text.replace("\n", " ");
      contentSubmit.textHTML = data.textHTML;
      contentSubmit.reference = data.reference;
      contentSubmit.type = "text";
      contentSubmit.addTopic = true;
      contentSubmit.assistantType = "meu-sputi";
      contentSubmit.client = {
        name: data.client,
      };
      contentSubmit.username = data.username.trim();
      if (ItemContent) {
        api
          .put(`/contents/${ItemContent.id}`, contentSubmit)
          .then(() => {
            toast.success("Conteúdo atualizado com sucesso!");
            setUpdate(!update);
            setLoading(false);
            reset();
          })
          .catch((error) => {
            toast.error("Erro ao atualizar conteúdo!");
            setLoading(false);
          });
      } else {
        contentSubmit.assistant = {
          name: "meu-sputi",
        };
        api
          .post("/contents", contentSubmit)
          .then(() => {
            toast.success("Conteúdo criado com sucesso!");
            setUpdate(!update);
            setLoading(false);
            reset();
          })
          .catch((error) => {
            toast.error("Erro ao criar conteúdo!");
            setLoading(false);
          });
      }
    }
  };

  return (
    <Row>
      <Col md="4">
        <Button
          block
          color="success"
          type="button"
          className="ml-3 mb-2"
          style={{ width: "180px" }}
          onClick={() => toggleModal("defaultModal")}
        >
          {ItemContent ? "Editar" : "Novo Conteúdo"}
        </Button>
        <Modal
          className="modal-dialog-centered"
          style={{ maxWidth: "840px" }}
          isOpen={defaultModal}
          toggle={() => toggleModal("defaultModal")}
        >
          <div className="modal-header">
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => toggleModal("defaultModal")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <CardBody>
              <form onSubmit={handleSubmit(onSubmit)}>
                <h6 className="heading-small text-muted mb-2">
                  Informações do conteúdo
                </h6>
                <div className="pl-lg-4">
                  <Row>
                    <Col md="12" lg="8">
                      <FormGroup></FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12" lg="8">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-reference"
                        >
                          Referencias
                        </label>
                        <input
                          className="form-control-alternative"
                          defaultValue={content.reference}
                          style={{
                            display: "block",
                            width: "100%",
                            height: "calc(1.5em + 1.25rem + 2px)",
                            padding: "0.625rem 0.75rem",
                            fontSize: "0.875rem",
                            fontWeight: "400",
                            lineHeight: "1.5",
                            color: "#8898aa",
                            backgroundColor: "#fff",
                            backgroundClip: "padding-box",
                            border: "1px solid #cad1d7",
                            borderRadius: "0.375rem",
                            boxShadow: "none",
                            transition:
                              "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                          }}
                          id="input-reference"
                          placeholder="Referencias"
                          type="text"
                          {...register("reference")}
                        />
                        <label
                          className="form-control-label mt-2"
                          htmlFor="input-reference"
                        >
                          Usuário
                        </label>
                        <input
                          className="form-control-alternative"
                          style={{
                            display: "block",
                            width: "100%",
                            height: "calc(1.5em + 1.25rem + 2px)",
                            padding: "0.625rem 0.75rem",
                            fontSize: "0.875rem",
                            fontWeight: "400",
                            lineHeight: "1.5",
                            color: "#8898aa",
                            backgroundColor: "#fff",
                            backgroundClip: "padding-box",
                            border: "1px solid #cad1d7",
                            borderRadius: "0.375rem",
                            boxShadow: "none",
                            transition:
                              "all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
                          }}
                          id="input-username"
                          placeholder="username"
                          type="text"
                          {...register("username")}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <hr className="my-4" />
                <h6 className="heading-small text-muted mb-4">Conteúdo</h6>
                <div className="pl-lg-4">
                  <FormGroup>
                    <NewEditor
                      initialContent={initialContent}
                      contentValue={textContent}
                      setContentValue={setTextContent}
                    />
                  </FormGroup>
                </div>
                <Button type="submit">Adicionar</Button>
              </form>
            </CardBody>
          </div>
        </Modal>
      </Col>
    </Row>
  );
};

export default ModalMeuSputiContent;
