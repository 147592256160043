/* eslint-disable no-undef */
// import "dotenv/config";
import axios from "axios";
import { toast } from "react-toastify";

let base = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL: base + "/admin/v1",
  params: {
    apikey: process.env.REACT_APP_APIGEE_APIKEY,
  },
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 403) {
      toast.error("Você não possui permissão para acessar este recurso.", {
        toastId: "permission-denied-notification",
      });
    }

    if (error.response.status === 401) {
      toast.error("Sua sessão expirou, por favor realize o login novamente.", {
        toastId: "unauthorized-notification",
      });
    }

    return Promise.reject(error);
  },
);

export default api;
