import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/AuthLayout.js";
import { ToastContainer } from "react-toastify";
import api from "service/api";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./config/msalConfig.js";
import { MsalProvider } from "@azure/msal-react";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import "react-toastify/dist/ReactToastify.css";
import "assets/plugins/nucleo/css/nucleo.css";
import "assets/css/custom-styles.css";
import "./assets/css/scroll.css";
import qualityApi from "service/qualityApi.js";
import newApi from "service/newApi.js";

const root = ReactDOM.createRoot(document.getElementById("root"));
const msalInstance = new PublicClientApplication(msalConfig);

export const ProtectedRoute = () => {
  const userToken = localStorage.getItem("admin_token");
  if (!userToken) {
    return <Redirect to="/auth/login" />;
  }
  api.defaults.headers["x-authorization"] = userToken;
  qualityApi.defaults.headers["x-authorization"] = userToken;
  newApi.defaults.headers["x-authorization"] = userToken;

  return <AdminLayout />;
};

root.render(
  <BrowserRouter>
    <MsalProvider instance={msalInstance}>
      <Switch>
        <Route path="/admin" render={() => <ProtectedRoute />} />
        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
        <Redirect from="/" to="/auth/login" />
      </Switch>
    </MsalProvider>
    <ToastContainer limit={3} />
  </BrowserRouter>,
);
