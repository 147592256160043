import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import api from "service/api";
import qualityApi from "service/qualityApi";
import { useMsal } from "@azure/msal-react";

import { Button, Card, CardBody, FormGroup, Form, Col } from "reactstrap";

const Login = () => {
  const { register, handleSubmit } = useForm();
  const history = useHistory();
  const { instance } = useMsal();

  useEffect(() => {
    const userToken = localStorage.getItem("admin_token");
    if (userToken) {
      api.defaults.headers["x-authorization"] = userToken;
      qualityApi.defaults.headers["x-authorization"] = userToken;
      history.push("/admin/conteudos");
    }
  }, []);

  const authLogin = async () => {
    try {
      instance
        .loginPopup()
        .then(async (response) => {
          const data = {
            username: response.account.username,
            unique_id: response.uniqueId,
          };
          await api
            .post("users/auth/azure", data, {
              headers: { Authorization: "Bearer " + response.idToken },
            })
            .then((response) => {
              localStorage.setItem("azure_user", data.username);
              localStorage.setItem(
                "admin_token",
                `Bearer ${response.data.access_token}`,
              );
              localStorage.setItem("admin_username", data.username);
              api.defaults.headers[
                "x-authorization"
              ] = `Bearer ${response.data.access_token}`;

              localStorage.setItem("permissions", response.data.permissions);
              toast.success("Login realizado com sucesso!");
              history.push("/admin/index");
            });
        })
        .catch((error) => {
          console.log("Error: " + error);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const handleLogin = async (data) => {
    await api
      .post("/users/auth", data)
      .then((response) => {
        if (response.data.access_token) {
          localStorage.setItem(
            "admin_token",
            `Bearer ${response.data.access_token}`,
          );
          localStorage.setItem("admin_username", data.username);
          api.defaults.headers[
            "x-authorization"
          ] = `Bearer ${response.data.access_token}`;

          localStorage.setItem("permissions", response.data.permissions);
          toast.success("Login realizado com sucesso!");
          history.push("/admin/index");
          return;
        } else {
          toast.error("Nao foi possível realizar seu login!");
        }
      })
      .catch(() => {
        toast.error("Erro ao realizar login, verifique seu usuário e senha.");
      });
  };

  return (
    <Col lg="5" md="7">
      <div style={{ textAlign: "center", margin: "40px" }}>
        <img
          alt="logo"
          className="logo"
          src={require("assets/img/brand/logo_white.png")}
        />
      </div>
      <Card className="bg-secondary shadow border-0">
        <CardBody className="px-lg-5 py-lg-5">
          <div className="text-center text-muted mb-4">
            <h3>Insira seus dados de login</h3>
          </div>
          <Form role="form" onSubmit={handleSubmit(handleLogin)}>
            <FormGroup className="mb-3">
              <input
                style={{
                  display: "block",
                  width: "100%",
                  height: "calc(1.5em + 1.25rem + 2px)",
                  padding: "0.625rem 0.75rem",
                  fontSize: "0.875rem",
                  fontWeight: "400",
                  lineHeight: "1.5",
                  color: "#8898aa",
                  border: "1px solid #cad1d7",
                  borderRadius: "4px",
                }}
                placeholder="Usuário"
                type="text"
                autoComplete="off"
                {...register("username")}
              />
            </FormGroup>
            <FormGroup>
              <input
                style={{
                  display: "block",
                  width: "100%",
                  height: "calc(1.5em + 1.25rem + 2px)",
                  padding: "0.625rem 0.75rem",
                  fontSize: "0.875rem",
                  fontWeight: "400",
                  lineHeight: "1.5",
                  color: "#8898aa",
                  border: "1px solid #cad1d7",
                  borderRadius: "4px",
                }}
                placeholder="Senha"
                type="password"
                {...register("password")}
              />
            </FormGroup>
            <div className="text-center">
              <Button color="primary" type="submit">
                Entrar
              </Button>
              <div>
                <hr />
              </div>
              <Button color="default" type="button" onClick={() => authLogin()}>
                Login com Azure AD
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default Login;
